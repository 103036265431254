@import './_all_vars.sass'

.pageWrapper
  padding: 1rem
  display: flex
  flex-direction: column

  .body
    position: relative
    background-color: white
    padding: 2rem
    border-radius: 8px
    margin: 2rem 0

  .loader
    justify-content: center

  .debug
    border: red 1px solid

  .topRow
    justify-content: space-between
    align-content: center

  .submitButtonRow
    justify-content: end

  .button
    margin: 5px
