@import './_all_vars.sass'

// ------ Loging page buttons START -------

.login
  button.is-primary
    &.is-white
      color: black
      border-color: white
      background-color: white

      &:hover:enabled:not(.is-loading),
      &:focus:enabled:not(.is-loading)
        color: white
        border-color: #555
        background-color: #555 !important


  button.is-secondary
    &.is-white
      color: white
      border-color: white
      background-color: transparent

      &:hover:enabled:not(.is-loading),
      &:focus:enabled:not(.is-loading)
        color: white
        border-color: #999
        background-color: #999 !important

  button.is-text
    &.is-white
      color: white

      &:hover:enabled:not(.is-loading),
      &:focus:enabled:not(.is-loading)
        color: #999 !important
        border-color: transparent

// ------ Loging page buttons END -------

.pagination-wrapper
  .pagination-list
    button.pagination-chevron
      i.material-icons.chevron-icon
        color: $smoke-testing-main-light

        &:hover
          background-color: $smoke-testing-main-dark
          color: white

    button.pagination-item
      &.is-active
        color: white

      color: $smoke-testing-main-light

      &:hover
        background-color: $smoke-testing-main-dark
        color: white

.field-wrapper 
  > label 
    .input-field 
      .search-icon
        color: $smoke-testing-main-light

.dropdown-wrapper 
  .dropdown-content 
    div.dropdown-selected
      background: $smoke-testing-dropdown-background

      &.is-open
        background: $smoke-testing-editor-tool-bar-background
        border: 1px solid $smoke-testing-editor-tool-bar-background

        i
          color: $smoke-testing-editor-tool-bar-background
          transform: rotate(180deg)
    
    .dropdown-options
      max-height: 202px
      overflow: auto

      .dropdown-item
        &:hover
          background: $smoke-testing-dropdown-option-background

.smoke-testing-icon-button
  button.button
    &.is-icon
      color: $smoke-testing-main-light
      background: transparent

      &:disabled
        color: $smoke-testing-main-light
        background: transparent

        &:hover
          color: $smoke-testing-main-light

      &:focus,
      &:hover
        color: white

.toast-container 
  .top-right
    top: 84px
    right: 40px

@media screen and (min-width: 1200px)
  .report-list-container
    .container
      min-width: 1140px
  
