.modalDivider
  position: relative
  border-bottom: 1px solid gray
  margin: 16px -24px 24px



.staticContent
  font-family: Arial, Helvetica, sans-serif
  padding: 80px 170px 80px 170px
  width: auto

  h1
    text-align: center

    sup
      font-size:6px

  sup
      font-size:4px

  .backToTopBtn
    text-align: right

  .list
    font-size: 16px

    > li
      font-weight: bold
      margin-top: 3em
  ul
    margin-bottom: 2em

    li
      line-height: 1.5em
      margin-bottom: 1em

  .tableList
    li
      font-family: Arial, Helvetica, sans-serif
      font-size: 14px

.center
  justify-content: center