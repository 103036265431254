$smoke-testing-main-dark: #263238;
$smoke-testing-main-light: #00796b;
$smoke-testing-pill-in-progress: #4418c2;
$smoke-testing-pill-closed: #c26a18;
$smoke-testing-pill-public: #246b03;
$smoke-testing-card-border: #d4d4d4;
$smoke-testing-settings-header-border: #d4d4d4;
$smoke-testing-dropdown-background: #fafafa;
$smoke-testing-nav-button-color: #b2dfdb;
$smoke-testing-editor-tool-bar-background: #1b4747;

$smoke-testing-status-not-started: #fff;
$smoke-testing-status-in-progress: #f6d510;
$smoke-testing-status-internal-review: #14a4de;
$smoke-testing-status-awaiting-external-review: #843dbc;
$smoke-testing-status-external-review-open: #de8d14;
$smoke-testing-status-external-review-closed: #da3b20;
$smoke-testing-status-approved: #00bd39;
$smoke-testing-status-creating-revision: #00eeff;
$smoke-testing-status-creating-revision-error: #f80a0a;
$smoke-testing-status-cloning-maps: #0077ff;

$smoke-testing-modal-background-opacity: rgba($smoke-testing-main-light, 0.2);
$smoke-testing-lock-own: #00bd39;
$smoke-testing-lock-other: #de8d14;

$smoke-testing-dropdown-option-background: #80cbc4;
$smoke-testing-dropdown-editor-tool-bar-selected: #00695c;
$smoke-testing-map-view-button-hover: #004d40;
$smoke-testing-drap-indicator: #919191;
$smoke-testing-text-light: #666;
$smoke-testing-text-disabled: #888;

$smoke-testing-text-reverse: $smoke-testing-dropdown-background;

$smoke-testing-navigator-menu-background: #fafafa;
$smoke-testing-navigator-menu-open: #f2f2f2;
$smoke-testing-navigator-menu-active-background: rgba(0, 121, 107, 0.1);
$smoke-testing-settings-menu-active-background: rgba(0, 121, 107, 0.1);
$smoke-testing-navigator-menu-active-font: #004d40;

$smoke-testing-structure-page-background: #fafafa;
$smoke-testing-structure-chapter-background: #f2f2f2;

$smoke-testing-map-preview-background-color: #e5e5e5;
$smoke-testing-map-tag: #c2185b;
$smoke-testing-input-character-left: #757575;
$smoke-testing-form-input: #666666;
$smoke-testing-user-search-item-color: #004053;
$smoke-testing-user-search-item-hover: #d9e9e7;

$primary--colour--main: $smoke-testing-main-light;
$primary--colour--main--dark: $smoke-testing-main-dark;
$primary--colour--main--light: $aurecon--cobaltblue--300;

$pill--colour--1: $smoke-testing-pill-in-progress;
$pill--colour--2: $smoke-testing-pill-closed;
$pill--colour--3: $smoke-testing-pill-public;
$pill--colour--4: $smoke-testing-main-light;
$pill--colour--5: $smoke-testing-map-tag;
$pill--colour--16: $aurecon--cobaltblue--050;

$smoke-testing-status-warning: #da3b20;

$smoke-testing-map-placeholder-background: #fafafa;

$smoke-testing-icon-colour-opacity: rgba($smoke-testing-main-light, 0.3);

$smoke-testing-time-slider-max-min-date-color: #515252;
$smoke-testing-time-slider-current-date-color: #6d9cce;

$smoke-testing-create-new-theme-base-theme-background-color: #f2f2f2;
$smoke-testing-create-new-colour-input-wrapper-background-color: #fafafa;

$smoke-testing-manage-feedback-type-background-color: #f2f2f2;
$smoke-testing-manage-feedback-type-border-color: #f2f2f2;
$smoke-testing-manage-feedback-type-border-color-active: $smoke-testing-main-light;
$smoke-testing-manage-feedback-type-border-color-hover: $smoke-testing-main-light;
$smoke-testing-manage-feedback-link-text-color: #121212;

$smoke-testing-external-feedback-field-background-color: #f2f2f2;

$smoke-testing-feedback-button-disabled-color: #919191;

$smoke-testing-search-content-icon-color: #00796B;
$smoke-testing-search-content-input-background-active: #eee;

$smoke-testing-report-setting-logo-container-text-color: #121212;
$smoke-testing-report-setting-logo-container-text-highlight-color: #00796B;
$smoke-testing-report-setting-logo-container-note-add-icon-color: #c4c4c4;

