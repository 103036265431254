@import './_all_vars.sass'

.uploaderContainer
  display: flex
  position: relative
  width: 100%
  height: 200px
  justify-content: center
  align-items: center
  text-align: center
  border: 1px dashed $smoke-testing-text-light
  background: transparent

  .uploaderPlaceholder
    text-align: center
    cursor: pointer

    .title
      font-size: 14px
      margin-top: 8px

    .subtitle
      font-size: 12px
      margin-top: 8px

    .tooltip
      margin-top: 8px
      font-size: 14px
      color: $smoke-testing-main-light

      span:global(.tooltip-wrapper)
        text-align: left

        >span >i
          color: $smoke-testing-main-light !important

        >div
          color: $smoke-testing-main-dark !important
          font-size: 12px

    p
      padding-bottom: 0
      margin-bottom: 0

  .fileNameBox
    display: flex
    flex-direction: row
    justify-content: right
    align-items: center
    position: absolute
    border-top: 1px solid rgba(0, 0, 0, 0.4)
    border-left: 1px solid rgba(0, 0, 0, 0.4)
    background: rgba(0, 0, 0, 0.4)
    color: #fff
    bottom: 0
    right: 0
    max-width: 90%

    .deleteIcon
      cursor: pointer
      color: #c4c4c4
      top: 0
      right: 4px

    p
      padding: 4px 8px
      margin: 0
      white-space: nowrap
      overflow: hidden
      text-overflow: ellipsis

  & a
    text-decoration: none
    cursor: pointer
    color: $smoke-testing-main-light
    font-weight: bold

  .addIcon
    cursor: pointer
    margin: 8px

.errorMessage
  font-size: 12px
  color: $smoke-testing-status-warning