@import './_all_vars.sass'

.pageWrapper
  padding: 1rem
  display: flex
  flex-direction: column
  clear: both
  display: table

  .body
    position: relative
    background-color: white
    padding: 2rem
    border-radius: 8px
    margin: 2rem 0
    justify-content: center
    padding-bottom: 200px
    width: 80vw

  .content
    flex-direction: column
